export default [
	{
		name: 'auth',
		path: '/auth',
		component: () => import('../components/auth'),
	},
	{
		path: '/', component: () => import('../components/layouts/layout-main'), meta: {},
		children: [
			{
				path: '/',
				name: 'orders',
				component: ()=>import('../components/orders'),
				meta: {api: "OrderListController"}
			},
			{
				path: '/orders-new',
				name: 'orders-new',
				component: ()=>import('../components/orders-edit'),
				meta: {api: "OrderController"}
			},
			{
				path: '/orders/:id',
				name: 'order',
				component: ()=>import('../components/orders-edit'),
				props: true,
				meta: {api: "OrderController"}
			},
			{
				path: '/plans',
				name: 'plans',
				component: ()=>import('../components/plans'),
				meta: {api: "PlanListController"}
			},
			{
				path: '/plans-new',
				name: 'plans-new',
				component: ()=>import('../components/plans-edit'),
				meta: {api: "PlanController"}
			},
			{
				path: '/plan/:id',
				name: 'plan',
				component: ()=>import('../components/plans-edit'),
				props: true,
				meta: {api: "PlanController"}
			},
			/*{
				path: '/plans/:periodId/:channelId',
				name: 'plan',
				component: ()=>import('../components/plans'),
				props: true,
				meta: {api: "PlanController"}
			},*/
			{
				path: '/profile',
				name: 'profile',
				component: ()=>import('../components/profile'),
				meta: {api: "RestrictedController"}
			},
			{
				path: '/orders/support',
				name: 'support',
				component: ()=>import('../components/support'),
				meta: {api: "RestrictedController"}
			},
			{
				path: '/logout',
				name: 'logout',
				component: ()=>import('../components/logout'),
				meta: {api: "RestrictedController"}
			},
			{
				path: '/error/:code',
				name: 'error',
				component: ()=>import('../components/error'),
				props: true,
			},
		]
	},
];
